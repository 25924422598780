import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as TextFieldMUI,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "react-admin";

export const LoadForecastModal = ({
  open,
  onClose,
  onConfirm,
  label = "Load Forecast Data",
  publicID,
}: any) => {
  const [confirmInput, setConfirmInput] = useState("");
  const confirmWord = publicID;

  const handleInputChange = (e: { target: { value: string } }) => {
    setConfirmInput(e.target.value.toLowerCase());
  };

  const handleConfirm = () => {
    if (confirmInput === confirmWord) {
      onConfirm();
      setConfirmInput("");
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="load-forecast-title"
      aria-describedby="load-forecast-description"
    >
      <DialogTitle id="load-forecast-title">{label}</DialogTitle>
      <DialogContent>
        <DialogContentText id="overwrite-description">
          This will overwrite any Steelbeam data entered on the site. Please
          confirm by entering public ID of the company.
        </DialogContentText>
        <Box my={0.5} fontWeight={"600"}>
          This action is irreversible
        </Box>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 1 }}>
          To confirm type the word <strong>{confirmWord}</strong> below:
        </Typography>
        <TextFieldMUI
          value={confirmInput}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          placeholder={confirmWord}
          inputProps={{
            "aria-label": `Confirm loading by typing ${confirmWord}`,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" label="Cancel"></Button>
        <Button
          onClick={handleConfirm}
          color="error"
          disabled={confirmInput !== confirmWord}
          label={"Confirm"}
        ></Button>
      </DialogActions>
    </Dialog>
  );
};
