import { BuildQueryFactory } from "ra-data-graphql";
import gql from "graphql-tag";
import { CREATE, GET_ONE, GET_LIST, DELETE, UPDATE } from "react-admin";
import { generateUrlSafeId } from "./generateUrlSafeId";
import { generateSessionId } from "./generateSessionId";

interface NextTokensPerPage {
  [pageNumber: number]: string | null;
}

interface NextTokens {
  [resourceName: string]: NextTokensPerPage;
}

// Initialize nextTokens with TypeScript types
const nextTokens: NextTokens = {};

interface QueryConfig {
  query: string;
  // eslint-disable-next-line no-unused-vars
  parseResponse: (response: any, params: any, resource: string) => any;
  // eslint-disable-next-line no-unused-vars
  getVariables?: (params: any, resource: string) => any;
}

const queries: Record<string, Record<string, QueryConfig>> = {
  loadForecastingData: {
    [CREATE]: {
      query: `
    mutation LoadForecastingData($input: LoadForecastingDataInput!, $tracing: TracingInput!) {
      loadForecastingData(input: $input, tracing: $tracing) {
        result {
          executionID
        }
            errors {
      __typename
      ... on GenericError {
        message
      }
      ... on InvalidParametersError {
        message
      }
    }
      }
    }
  `,
      parseResponse: (response: any) => {
        return {
          data: {
            id: response.data.loadForecastingData.executionID,
          },
        };
      },
      getVariables: (params: any) => {
        return {
          input: {
            publicID: params?.data?.input.id,
          },
          tracing: params?.data?.tracing,
        };
      },
    },
  },
  pipelineForOrganization: {
    [UPDATE]: {
      query: `
    mutation UpdatePipelineETLReady($input: UpdatePipelineETLReadyInput!, $tracing: TracingInput!) {
      updatePipelineETLReady(input: $input, tracing: $tracing) {
        pipeline {
          organizationID
          etlReady
        }
      }
    }
  `,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.updatePipelineETLReady.pipeline,
          id: response.data.updatePipelineETLReady.pipeline.organizationID,
        },
      }),
      getVariables: (params: any) => {
        console.log("params", params);
        return {
          input: {
            organizationID: params.id,
            etlReady: params.data.input.etlReady,
          },
          tracing: params.meta?.tracing,
        };
      },
    },
    [GET_ONE]: {
      query: `
        query GetPipelineForOrganization($input: GetPipelineForOrganizationInput!, $tracing: TracingInput!) {
          getPipelineForOrganization(input: $input, tracing: $tracing) {
            pipeline {
              organizationID
              etlReady
              infrastructureStatus
              createdAt
              updatedAt
              integrations {
                integrationID
              }
            }
          }
        }
      `,
      parseResponse: (response: any) => {
        return {
          data: {
            ...response.data.getPipelineForOrganization.pipeline,
            id: response.data.getPipelineForOrganization.pipeline
              ?.organizationID,
          },
        };
      },
      getVariables: (params: any) => {
        return {
          input: {
            organizationID: params.id,
          },
          tracing: params.meta.tracing,
        };
      },
    },
    [CREATE]: {
      query: `
      mutation CreatePipelineForOrganization($input: CreatePipelineForOrganizationInput!, $tracing: TracingInput!) {
          createPipelineForOrganization(input: $input, tracing: $tracing) {
            pipeline {
              organizationID
              updatedAt
              createdAt
              integrations {
                integrationID
              }
            }
          }
        }
    `,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.createPipelineForOrganization.pipeline,
          id: response.data.createPipelineForOrganization.pipeline
            .organizationID,
        },
      }),
      getVariables: (params: {
        data: {
          input: {
            integrationID: string;
            organizationID: string;
            configuration: any;
          };
        };
      }) => {
        return {
          input: params.data.input,
        };
      },
    },
    [DELETE]: {
      query: `mutation DeletePipelineForOrganization($input: DeletePipelineForOrganizationInput!, $tracing: TracingInput!) {
          deletePipelineForOrganization(input: $input, tracing: $tracing) {
            pipeline {
              organizationID
              integrations {
                integrationID
              }
            }
          }
        }`,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.deletePipelineForOrganization.pipeline,
        },
      }),
      getVariables: (params: { id: string }) => {
        return {
          input: {
            organizationID: params?.id,
          },
        };
      },
    },
  },
  integrations: {
    [CREATE]: {
      query: `
        mutation CreateIntegration($input: CreateIntegrationInput!, $tracing: TracingInput!) {
          createIntegration(input: $input, tracing: $tracing) {
            integration {
              name
              integrationID
              name
              description
              generallyAvailable
              createdAt
            }
          }
        }
      `,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.createIntegration.integration,
          id: response.data.createIntegration.integration.integrationID,
        },
      }),
      getVariables: (params: {
        data: { input: { publicID: string; name: string; email: string } };
      }) => {
        return {
          input: params.data.input,
        };
      },
    },
    [DELETE]: {
      query: `mutation DeleteIntegration($input: DeleteIntegrationInput!, $tracing: TracingInput!) {
          deleteIntegration(input: $input, tracing: $tracing) {
            integration {
              integrationID
            }
          }
        }`,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.deleteIntegration.integration,
        },
      }),
      getVariables: (params: { id: string }) => {
        return {
          input: {
            integrationID: params?.id,
          },
        };
      },
    },
    [GET_ONE]: {
      query: `
        query GetIntegration($input: GetIntegrationInput!, $tracing: TracingInput!) {
          getIntegration(input: $input, tracing: $tracing) {
            integration {
              integrationID
              createdAt
              updatedAt
              name
              description
              generallyAvailable
            }
          }
        }
      `,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.getIntegration.integration,
          id: response.data.getIntegration.integration.integrationID,
        },
      }),
      getVariables: (params: any) => {
        console.log("params", params);
        return {
          input: {
            integrationID: params.id,
          },
        };
      },
    },
    [GET_LIST]: {
      query: `
        query ListIntegrations($tracing: TracingInput!, $page: PaginationInput!) {
          listIntegrations(tracing: $tracing, page: $page) {
            nextToken
            items {
              integrationID
              name
              description
              generallyAvailable
              createdAt
              updatedAt
               configurationTemplate {
              key
              label
               type
              }
            }
          }
        }
      `,
      parseResponse: (response: any) => ({
        data:
          response.data.listIntegrations.items?.map((item: any) => ({
            ...item,
            id: item.integrationID,
          })) || [],
        total: response.data.listIntegrations.items?.length || 0,
      }),
      getVariables: (params: any) => ({
        page: {
          limit: params.pagination.perPage,
          nextToken: params.pagination.nextToken,
        },
      }),
    },
  },
  organizations: {
    [CREATE]: {
      query: `
        mutation CreateOrganization($input: CreateOrganizationInput!, $tracing: TracingInput!) {
          createOrganization(input: $input, tracing: $tracing) {
            organization {
              name
              organizationID
              creatorAccountID
              createdAt
              members {
                items {
                  accountID
                }
              }
            }
          }
        }
      `,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.createOrganization.organization,
          id: response.data.createOrganization.organization.organizationID,
        },
      }),
      getVariables: (params: {
        data: { input: { publicID: string; name: string; email: string } };
      }) => {
        return {
          input: params.data.input,
        };
      },
    },
    [UPDATE]: {
      query: `mutation UpdateOrganization($input: UpdateOrganizationInput!, $tracing: TracingInput!) {
          updateOrganization(input: $input, tracing: $tracing) {
            organization {
              organizationID,
              name
              demo
            }
          }
        }`,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.updateOrganization.organization,
          id: response.data.updateOrganization.organization.organizationID,
        },
      }),
      getVariables: (params: {
        data: {
          input: { demo: boolean; name: string; organizationID: string };
        };
      }) => {
        console.log("params", params);
        return {
          input: params.data.input,
        };
      },
    },
    [DELETE]: {
      query: `mutation DeleteOrganization($input: DeleteOrganizationInput!, $tracing: TracingInput!) {
          deleteOrganization(input: $input, tracing: $tracing) {
            organization {
              name
              organizationID
            }
          }
        }`,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.deleteOrganization.organization,
        },
      }),
      getVariables: (params: { id: string }) => {
        return {
          input: {
            organizationID: params?.id,
          },
        };
      },
    },
    [GET_ONE]: {
      query: `
        query getOrganization($organizationID: String!, $tracing: TracingInput!) {
          getOrganization(organizationID: $organizationID, tracing: $tracing) {
            organization {
              createdAt
              name
              demo
              organizationID
              publicID
              status
              updatedAt
              members {
                items {
                  accountID
                  role
                  account {
                    firstName
                    lastName
                    contactEmail
                  }
                }
              }
            }
          }
        }
      `,
      parseResponse: (response: any) => ({
        data: {
          ...response.data.getOrganization.organization,
          id: response.data.getOrganization.organization.organizationID,
        },
      }),
      getVariables: (params: any) => ({
        organizationID: params.id,
      }),
    },
    [GET_LIST]: {
      query: `
        query ListOrganizations($tracing: TracingInput!, $page: PaginationInput!) {
          listOrganizations(tracing: $tracing, page: $page) {
            nextToken
            items {
              name
              organizationID
              publicID
              status
              creatorAccountID
              createdAt
              demo
            }
          }
        }
      `,
      parseResponse: (response: any, params: any, resource: string) => {
        const items =
          response.data.listOrganizations.items?.map((item: any) => ({
            ...item,
            id: item.organizationID,
          })) || [];

        const hasNextPage = !!response.data.listOrganizations.nextToken;
        const hasPreviousPage = params.pagination.page > 1;

        // Initialize nextTokens for the resource if not already present
        if (!nextTokens[resource]) {
          nextTokens[resource] = {};
        }

        // Store nextToken for the next page
        nextTokens[resource][params.pagination.page + 1] =
          response.data.listOrganizations.nextToken ?? null;

        return {
          data: items,
          pageInfo: {
            hasNextPage,
            hasPreviousPage,
          },
        };
      },
      getVariables: (params: any, resource: string) => {
        const currentPage = params.pagination.page;
        const perPage = params.pagination.perPage;

        // Retrieve nextToken for the current page
        const nextToken = nextTokens[resource]?.[currentPage] ?? null;

        return {
          page: {
            limit: perPage,
            nextToken,
          },
        };
      },
    },
    // Add UPDATE and DELETE configurations here
  },
  accounts: {
    [GET_ONE]: {
      query: `
        query GetAccount($tracing: TracingInput!) {
          getAccount(tracing: $tracing) {
             account {
                accountID
                status
                active
                firstName
                lastName
                contactEmail
                createdAt
                updatedAt
                organizations {
                  role
                  organizationID
                  organization {
                    name
                  }
                }
             }
          }
        }
      `,
      parseResponse: (response: any) => {
        return {
          data: {
            ...response.data.getAccount.account,
            id: response.data.getAccount.account.accountID,
          },
        };
      },
      getVariables: (params) => {
        return {
          tracing: {
            accountID: params.id,
            sessionID: params.meta.sessionID,
            pageID: params.meta.pageID,
          },
        };
      },
    },
    [GET_LIST]: {
      query: `
        query ListAccounts($tracing: TracingInput!, $page: PaginationInput!) {
          listAccounts(tracing: $tracing, page: $page) {
            nextToken
            items {
              firstName
              lastName
              accountID
              organizations {
                organizationID
                role
                organization {
                  name
                }
              }
            }
          }
        }
      `,
      parseResponse: (response: any, params: any, resource: string) => {
        const items =
          response.data.listAccounts.items?.map((item: any) => ({
            ...item,
            id: item.accountID,
          })) || [];

        const hasNextPage = !!response.data.listAccounts.nextToken;
        const hasPreviousPage = params.pagination.page > 1;

        // Initialize nextTokens for the resource if not already present
        if (!nextTokens[resource]) {
          nextTokens[resource] = {};
        }

        // Store nextToken for the next page
        nextTokens[resource][params.pagination.page + 1] =
          response.data.listAccounts.nextToken ?? null;

        return {
          data: items,
          pageInfo: {
            hasNextPage,
            hasPreviousPage,
          },
        };
      },
      getVariables: (params: any, resource: string) => {
        const currentPage = params.pagination.page;
        const perPage = params.pagination.perPage;

        // Retrieve nextToken for the current page
        const nextToken = nextTokens[resource]?.[currentPage] ?? null;

        return {
          page: {
            limit: perPage,
            nextToken,
          },
        };
      },
    },
  },
  // Add more resources here if they need GraphQL support
};

const buildQuery: BuildQueryFactory = () => {
  return (fetchType: string, resource: string, params: any) => {
    console.log("buildQuery called with:", { fetchType, resource, params });

    if (!queries[resource] || !queries[resource][fetchType]) {
      throw new Error(
        `Unsupported resource or fetch type: ${resource} - ${fetchType}`
      );
    }

    const sessionID = generateSessionId();
    const sourceRequestID = generateUrlSafeId();

    const pageID = `${
      import.meta.env.VITE_APP_ENV
    }.${resource}.${fetchType.toLowerCase()}`;

    const { query, parseResponse, getVariables } = queries[resource][fetchType];

    let variables: any = {
      tracing: {
        sessionID,
        pageID,
        sourceRequestID,
      },
    };

    if (getVariables) {
      variables = {
        ...variables,
        ...getVariables(params, resource),
      };
    }

    return {
      query: gql(query),
      variables,
      parseResponse: (response: any) =>
        parseResponse(response, params, resource),
    };
  };
};

export default buildQuery;
